import { createStore } from 'vuex'
const store= createStore({
  state: {
    //登录显示的类型//0登录 1 快速注册 2 手机号注册 3 修改密码 4 找回密码 5 实名认证
    loginRegisterType:-1,
    //是否显示网页
    showWeb:{},
    //是否显示loading框
    loading:false,
    //是否显示身份验证失效框
    tokenFailed:false,
    //gm关注事件
    GMAttentionChange:{},
     //收藏事件
     collectChange:{},
    //首页任务跳转任务
    activiSkip:{},
    closeBox:-1,
    findGame:{},
    //videoListBack  1.在玩直播详情返回  2 发现直播返回  3 发现短视频返回   5 tab切换
    videoListBack:{},
    hasTask:0,
    tempGameInfo:"",
    //用户信息
    user:(localStorage.getItem("user") !=null && localStorage.getItem("user") !="")?JSON.parse(localStorage.getItem("user")):{},
    userLocalGameInfoMap:(localStorage.getItem("localGameInfo") !=null && localStorage.getItem("localGameInfo") !="")?JSON.parse(localStorage.getItem("localGameInfo")):{},
    baseInfo:(localStorage.getItem("baseInfo")!=null&&localStorage.getItem("baseInfo")!="")?JSON.parse(localStorage.getItem("baseInfo")):{},
    boxTheme: localStorage.getItem("boxTheme") ? localStorage.getItem("boxTheme") : ''
  },
  mutations: {
    updateVideoListBack(state,result){
      state.videoListBack=result
    },
    updateHasTask(state,result){
      state.hasTask=result
    },
    updateGMAttentionChange(state,id){
      state.GMAttentionChange=id
    },
    updateCollectChange(state,id){
      state.collectChange=id
    },
    updateLoginPopType(state,type){
      state.loginRegisterType=type
    },
    updateCloseBoxType(state,type){
      state.closeBox=type
    },
    updateTokenFailed(state,type){
      state.tokenFailed=type
    },
    updateActiviSkip(state,data){
      state.activiSkip=data
    },
    updateFindGame(state,data){
      state.findGame=data
    },
    updateTempGameInfo(state,data){
      state.tempGameInfo=data
    },
    updateShowWeb(state,type){
      state.showWeb=type
    },
    showLoading(state){
      state.loading=true
    },
    hideLoading(state){
      state.loading=false
    },
    updateBaseInfo(state,info){
      state.baseInfo=JSON.parse(info)
      if(info==null||info==""){
        localStorage.setItem("baseInfo","")
      }else{
        localStorage.setItem("baseInfo",info)
      }
    },
    updateBoxTheme(state, theme){
        state.boxTheme = theme;
        localStorage.setItem("boxTheme",theme);
    },
    updateUser(state,user){
      state.user=user
      if(user==null||user==""){
        localStorage.setItem("user","")
      }else{
        localStorage.setItem("user",JSON.stringify(user))
      }
    },
    updateUserLocalGameInfoMap(state,info){
      state.userLocalGameInfoMap=info
      if(info==null||info==""){
        localStorage.setItem("userLocalGameInfoMap","")
      }else{
        localStorage.setItem("userLocalGameInfoMap",JSON.stringify(info))
      }
    }

  },
  actions: {
  },
  modules: {

  }
})
export default store
