/**
 * 
 * @returns 获取用户信息
 */
export const getUserInfo = function(){
    if(isAndroid()){
        return $App.getUserInfo("")
    }else{
        window.webkit.messageHandlers.getUserInfo.postMessage('');     
    }
}

export const isAndroid =function(){

    // let ua= navigator.userAgent;
    // let android = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端
    // let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if(window.$App){
        return true
    }
    return false
}

/**
 * 
 * @returns 关闭沙盒界面
 */
export const closeDrawer = function(){
    if(isAndroid()){
        return $App.closeDrawer("")
    }else{
        window.webkit.messageHandlers.closeDrawer.postMessage('');     
    }
}

/**
 * 
 * @returns app更新
 */
export const updateApp = function(data){
    if(isAndroid()){
        return $App.updateApp(data)
    }else{
        window.webkit.messageHandlers.updateApp.postMessage(data);     
    }
}

/**
 *
 * @returns 切换用户信息
 */
export const changeUserInfo = function(userInfo,neewRefresh){
    if(isAndroid()){
        return $App.changeUserInfo(userInfo,neewRefresh)
    }else{
        window.webkit.messageHandlers.changeUserInfo.postMessage(userInfo);
    }
}
/**
 *
 * @returns 切换用户信息
 */
export const saveAccountGameInfo = function(gameInfo){
    if(isAndroid()){
        return $App.saveAccountGameInfo(gameInfo)
    }else{
        window.webkit.messageHandlers.saveAccountGameInfo.postMessage(gameInfo);
    }
}


/**
 *
 * @returns 登陆第三方游戏
 */
export const loginThirdGame = function(gameInfo){
    if(isAndroid()){
        return $App.loginThirdGame(gameInfo)
    }else{
        window.webkit.messageHandlers.loginThirdGame.postMessage(gameInfo);
    }
}

/**
 *
 * @returns 切换用户信息
 */
export const showLoginBtn = function(){
    if(isAndroid()){
        return $App.showLoginBtn("")
    }else{
        window.webkit.messageHandlers.showLoginBtn.postMessage("");
    }
}

/**
 *
 * @returns 切换用户信息
 */
export const updateAccountGameInfo = function(gameInfo){
    if(isAndroid()){
        return $App.updateAccountGameInfo(gameInfo)
    }else{
        window.webkit.messageHandlers.updateAccountGameInfo.postMessage(gameInfo);
    }
}

/**
 *
 * @returns 清除缓存
 */
export const clean = function(){
    if(isAndroid()){
        return $App.clean()
    }else{
        window.webkit.messageHandlers.clean.postMessage("");
    }
}

/**
 * 
 * @returns 打开游戏
 */
export const openGame = function(gameInfo){
    if(isAndroid()){
        return $App.openGame(gameInfo)
    }else{
        window.webkit.messageHandlers.openGame.postMessage(gameInfo);     
    }
   
}

/**
 *
 * @returns 打开游戏
 */
export const changeGame = function(gameInfo){
    if(isAndroid()){
        if ($App.changeGame){
            return $App.changeGame(gameInfo)
        }else{
            return $App.openGame(gameInfo)
        }
    }else{
        if (window.webkit.messageHandlers.changeGame!=null){
            window.webkit.messageHandlers.changeGame.postMessage(gameInfo);
        }
    }
}

/**
 * 录屏 0开始 1 结束
 * @param {*} type 
 * @returns 
 */
export const record = function(type){
    if(isAndroid()){
        return $App.record(type)
    }else{
        window.webkit.messageHandlers.record.postMessage(type);     
    }
  
}

/**
 * 录屏 0开始 1 结束
 * @param {*} type
 * @returns
 */
export const newRecord = function(type){
    if(isAndroid()){
        return $App.newRecord(type)
    }else{
        window.webkit.messageHandlers.newRecord.postMessage(type);
    }

}

/**
 * 
 * @returns 刷新游戏
 */
export const refreshGame = function(){
    if(isAndroid()){
        return $App.refreshGame("")
    }else{
        window.webkit.messageHandlers.refreshGame.postMessage("");     
    }
   
}

/**
 * 
 * @returns 屏幕截图
 */
export const gameShot = function(){
    if(isAndroid()){
        return $App.gameShot("")
    }else{
        window.webkit.messageHandlers.gameShot.postMessage("");     
    }
 
}

/**
 * 显示toast
 * @returns 
 */
export const showToast = function(msg){
    if(isAndroid()){
        return $App.showToast(msg)
    }else{
        window.webkit.messageHandlers.showToast.postMessage(msg);     
    }
    
}

/**
 * 退出游戏
 * @returns 
 */
export const exitGame = function(gameInfo){
    if(isAndroid()){
        return $App.exitGame(gameInfo)
    }else{
        window.webkit.messageHandlers.exitGame.postMessage(gameInfo);     
    }
    
}

/**
 * 分享游戏
 * @returns 
 */
export const shareGame = function(gameInfo){
    if(isAndroid()){
        return $App.shareGame(gameInfo)
    }else{
        window.webkit.messageHandlers.shareGame.postMessage(gameInfo);     
    }
    
}

/**
 * 获取游戏列表
 * @returns 
 */
export const getGames = function(type){
    if(isAndroid()){
        return $App.getGames(type)
    }else{
        window.webkit.messageHandlers.getGames.postMessage(type);     
    }
    
}

/**
 * 设置主游戏
 * @returns 
 */
export const setMainGame = function(gameInfo){
    if(isAndroid()){
        return $App.setMainGame(gameInfo)
    }else{
        window.webkit.messageHandlers.setMainGame.postMessage(gameInfo);     
    }
   
}

/**
 * 创建快捷方式
 * @returns 
 */
export const shortCut = function(gameInfo){
    if(isAndroid()){
        return $App.shortCut(gameInfo)
    }else{
        window.webkit.messageHandlers.shortCut.postMessage(gameInfo);     
    }
    
}

/**
 * 刷新整个页面
 * @returns 
 */
export const refresh = function(){
    if(isAndroid()){
        return $App.refresh()
    }else{
        window.webkit.messageHandlers.refresh.postMessage("");     
    }
    
}

export const copyCode = function(data){
    if(isAndroid()){
        return $App.copyCode(data)
    }else{
        window.webkit.messageHandlers.copyCode.postMessage(data);     
    }
    
}

export const launchApp = function(data){
    if(isAndroid()){
        return $App.launchApp(data)
    }else{
        window.webkit.messageHandlers.launchApp.postMessage(data);  
    }
}

export const loginOut = function(){
    if(isAndroid()){
        return $App.loginOut()
    }else{
        window.webkit.messageHandlers.loginOut.postMessage("");     
    }
}

export const taskShow = function(type){
    if(isAndroid()){
        return $App.taskShow(type)
    }else{
        window.webkit.messageHandlers.taskShow.postMessage(type);     
    }
}

export const quickChangeGame = function(type){
    if(isAndroid()){
        return $App.quickChangeGame(type)
    }else{
        window.webkit.messageHandlers.quickChangeGame.postMessage(type);     
    }
}

export const loginLoadComplete = function(){
    if(isAndroid()){
        return $App.loginLoadComplete("")
    }else{
        window.webkit.messageHandlers.loginLoadComplete.postMessage("");     
    }
}

export const getQuickChangeState = function(){
    if(isAndroid()){
        return $App.getQuickChangeState("")
    }else{
        window.webkit.messageHandlers.getQuickChangeState.postMessage("");     
    }
}

export const changeFrameToWindow = function(){
    if(isAndroid()){
        return $App.changeFrameToWindow("")
    }else{
        window.webkit.messageHandlers.changeFrameToWindow.postMessage("");
    }
}


export const changeFrameToScreen = function(){
    if(isAndroid()){
        return $App.changeFrameToScreen("")
    }else{
        window.webkit.messageHandlers.changeFrameToScreen.postMessage("");
    }
}

export const openSoftInputMode = function(type){
    if(isAndroid()){
        return $App.openSoftInputMode(type)
    }else{
        //window.webkit.messageHandlers.openSoftInputMode.postMessage(type);
    }
}



export const startBrowser = function(url){
    if(isAndroid()){
        return $App.startBrowser(url)
    }else{
        window.webkit.messageHandlers.startBrowser.postMessage(url);
    }
}


export const antiAddiction = function(){
    if(isAndroid()){
        return $App.antiAddiction("")
    }else{
        window.webkit.messageHandlers.antiAddiction.postMessage("");
    }
}

export const saveImage = function(url){
    if(isAndroid()){
        return $App.saveImage(url)
    }else{
        window.webkit.messageHandlers.saveImage.postMessage(url);
    }
}

export const openPrivacy = function(url){
    if(isAndroid()){
        return $App.openActivity(url)
    }else{
        window.webkit.messageHandlers.openActivity.postMessage(url);
    }
}






