import { Toast } from 'vant';
const pictureUrl = "http://47.97.5.216:8090/";
const global_const={
    videoBack:{
        //在玩直播详情返回
        "cruGame":1,    
        //直播列表详情返回
        "videoList":2,
        //短视频返回
        "shortVideo":3,
        //游戏详情，直播返回
        "gameDetail":4,
        //发现tab切换
        "tabChange":5,
        "strategy":6,
        "scoreGitBag":7,
    },
    loginType:{
        Login:0,
        AutoLogin:1,
        Register:2,
        SmallSelect:3,
        FindPassword:4,
        RealName:5,
        SmallAccount:6,
    },
    MC_SHARE:"https://mc-mall-h5.michong.org.cn/download_page?"
}

export const toast=function(msg){
    Toast({
        message: '<span style="display:inline-block;padding: 8px 30px 8px 30px;box-sizing: border-box;text-align: center;background: #1A1A1A;border-radius: 4px 4px 4px 4px;opacity: 1; border: 1px solid #5E5F61;color: white;font-size:12px;"> '+msg+' </span>',
        type: 'html'
      })
}

export default {
    pictureUrl,
    toast,
    global_const
};