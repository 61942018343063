
const routes = [
    {
        path: '/',
        redirect: (to,from)=>{
            console.log(window.location.href)
            var user = window.localStorage.getItem('user');
            if ((user == null || user=="" || (to.query!=null&&to.query.info!=null)|| (to.query!=null&&to.query.iosInfo!=null))&&to.query.newLogin==null) {
                console.log('/login')
                return '/login'
            } else  if ((user == null || user=="" || (to.query!=null&&to.query.info!=null)|| (to.query!=null&&to.query.iosInfo!=null&&to.query.newLogin==!null))) {
                console.log('/newLogin')
                return '/newLogin'
            } else {
                console.log('/home')
                return '/home'
            }
        }
    },
    {
        name:'fenstrulePlay',
        path:'/fenstrulePlay',
        component:()=>import('@/components/common/fenstrulePlay')
    },
    {
        name:'largeVideo',
        path:'/largeVideo',
        component:()=>import('@/components/common/largeVideo')
    },
    {
        name:'videoPlay',
        path:'/videoPlay',
        component:()=> import('@/components/common/videoPlay')
    },
    {
        name:'seedFenstrulePlay',
        path:'/seedFenstrulePlay',
        component:()=>import('@/components/common/seedFenstrulePlay')
    },

    {
        name: 'login',
        path: '/login',
        meta: {keepAlive: true} ,
        component: () => import('@/components/userCenter/UserLogin'),
    },
    {
        name: 'newLogin',
        path: '/newLogin',
        meta: {keepAlive: true} ,
        component: () => import('@/components/userCenter/NewUserLogin'),
    },
    {
        name: 'home',
        path: '/home',
        meta: {keepAlive: true} ,
        redirect:'/home/gameHome',
        component: () => import('@/components/Home'),
        children:[
            {
                name: 'cruGame',
                path: 'cruGame',
                meta: {keepAlive: false} ,
                component: () => import('@/components/cruGame/cruGame'),
            },
            {
                name: 'gameHome',
                path: 'gameHome',
                meta: {keepAlive: true} ,
                component: () => import('@/components/gamehome/gameHome'),
            },
            {
                name: 'money',
                path: 'money',
                component: () => import('@/components/gamehall/money/money')
            },
           
            {
                name: 'gameHall',
                path: 'gameHall',
                redirect:'/home/gameHall/allGame',
                component: () => import('@/components/gamehall/gameHall'),
                meta: {keepAlive: true} ,
                children: [
                    
                    {
                        name: 'allGame',
                        path: 'allGame',
                        meta: {keepAlive: true} ,
                        component: () => import('@/components/gamehall/allGame/AllGame')
                    },
                    {
                        name:'shortVideo',
                        path:'shortVideo',
                        component:()=>import('@/views/shortVideo')
                    },
                    {
                        name:'strategy',
                        path:'strategy',
                        component:()=>import('@/views/shortVideo/strategy')
                    },    
                    {
                        name:'directSeeding',
                        path:'directSeeding',
                        component:()=>import('@/views/directSeeding')
                    },
                    {
                        name:'seedVideoPlay',
                        path:'seedVideoPlay',
                        component:()=>import('@/components/common/seedVideoPlay')
                    },


                ]
            },       
            
            {
                name: 'userCenterHome',
                path: '/userCenterHome',
                meta: {keepAlive: true} ,
                component: () => import('@/components/userCenter/UserCenterHome')
            },
            {
                name: 'gitbag',
                path: '/gitbag',
                component: () => import('@/components/userCenter/gitbag/GitBag')
            },
            {
                name: 'gameDetail',
                path: '/gameDetail',
                component: () => import('@/components/cruGame/newGameDetail'),
                // children:[{
                //     name: 'gameDetailIntroduce',
                //     path: '/gameDetailIntroduce',
                //     component: () => import('@/components/cruGame/gameIntroduce')
                // },{
                //     name: 'gameDetailWelfare',
                //     path: '/gameDetailWelfare',
                //     component: () => import('@/components/cruGame/gameWelfare')
                // }]
            },

            {
                name: 'vip',
                path: '/vip',
                component: () => import('@/components/userCenter/Vip')
            }
        
        ]
    },
    {
        name: 'share',
        path: '/share',
        component: () => import('@/components/share/share'),
    }
];

export default routes

