import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import { List } from 'vant';
import { Cell, CellGroup,Checkbox } from 'vant';
import { IndexBar, IndexAnchor } from 'vant';
import { Tab, Tabs } from 'vant';
import { Lazyload } from 'vant';
import { PullRefresh } from 'vant';
import {Col, Row, Grid, GridItem,Button ,Popup ,RadioGroup, Cascader,Radio,Field,Loading ,Swipe, SwipeItem,Circle,Progress, Image as VanImage} from 'vant';
import { Toast } from 'vant';
import VueClipboard from 'vue-clipboard2'
import store from './store'
import global  from './global'
import * as AppInter  from './app/app'
import * as base64 from 'js-base64'
// import VConsole from 'vconsole'
// let vConsole = new VConsole()
// import VConsole from 'vconsole/dist/vconsole.min.js'
// let vConsole = new VConsole()
const app=createApp(App)
// 定义全局变量
app.config.globalProperties.$global= global;
app.config.globalProperties.$appInter= AppInter;
app.config.globalProperties.$base64= base64;
 app.use(router)
.use(List)
.use(Cell)
 .use(Lazyload)
.use(CellGroup)
.use(IndexBar)
.use(IndexAnchor)
.use(RadioGroup)
.use(Radio)
.use(Tab)
.use(Tabs)
.use(PullRefresh)
.use(Checkbox)
.use(Col)
.use(Row)
.use(Cascader )
.use(Grid)
.use(GridItem)
.use(Button)
.use(Popup)
.use(Field)
.use(VanImage)
.use(Loading)
.use(Swipe)
.use(SwipeItem)
.use(Progress)
.use(Toast)
.use(VueClipboard)
.use(store)
.use(Circle)
.mount('#app')


