<template>
  <div class="container">
        <router-view v-slot="{ Component }">
        <transition name="fade">
          <keep-alive :include="cts">
            <component class="view" :is="Component"></component>
          </keep-alive>
        </transition>
        </router-view>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
        cts:['home'],	
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.8rem;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  ms-user-select: none;
   
    
}
</style>
<style lang="scss">
:root{
  // --van-toast-text-color:red;
  // --van-toast-default-min-height:30px;
  // --van-toast-background-color:blue;

}
 
</style>