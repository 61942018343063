<template>
  <Main />
</template>

<script>
import Main from './components/main'
import router from "@/router";

export default {
  name: 'App',
  components: {
    Main
  },
  methods:{
    closeBox(type){
      console.log("closeBox:"+type)
      this.$store.commit("updateCloseBoxType", type);
    },

    getUserLocalGameInfoMap(){
      return this.$store.state.userLocalGameInfoMap|| {}
    },
    getLocalGameInfo(userId){
      return this.getUserLocalGameInfoMap()[userId]||{}
    },
    appGameLogin(game) {
      router.push("/newLogin?small=1")
      this.$store.commit("updateTempGameInfo", game);
    },
    updateUserLocalGameInfoMap(gameInfo) {
      this.$store.commit("updateUserLocalGameInfoMap", gameInfo);
    },
    //游戏退出
    gameUserLoginOut(gameAccountJson) {
      console.log("22222222",gameAccountJson)
      let gameAccount=JSON.parse(gameAccountJson);
      let gameId= (gameAccount.gameId+"").split('_')[0]
      let account=gameAccount.id;
      if (this.$store.state.user!=null&&account!=null&&gameId!=null){
        let localGameInfo=this.getLocalGameInfo(this.$store.state.user.id)
        let gameAccountList= localGameInfo.gameIng ? localGameInfo.gameIng['game_'+gameId] : null;
        if (gameAccountList!=null&&gameAccountList.indexOf(account)>=0){
          gameAccountList.splice(gameAccountList.indexOf(account),1)
        }
        let accountGameList= localGameInfo.gameIng ? localGameInfo.gameIng['id_'+account] : null;
        if (accountGameList!=null&&accountGameList.indexOf(gameId)>=0){
          accountGameList.splice(accountGameList.indexOf(gameId),1)
        }
        let userLocalGameInfoMap = this.getUserLocalGameInfoMap()
        userLocalGameInfoMap[this.$store.state.user.id]=localGameInfo;
        this.updateUserLocalGameInfoMap(userLocalGameInfoMap)
      }
    },
  },
  mounted(){
    window.closeBox = this.closeBox
    //接口App小号登录时，发送的游戏信息
    window.appGameLogin = this.appGameLogin
    //游戏退出
    window.gameUserLoginOut = this.gameUserLoginOut
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
*{
    box-sizing: border-box;
}
</style>
